<navigation-logo class="logo"></navigation-logo>

<!-- <p-scrollPanel class="list" [style]="{ width: '100%', height: 'calc(100vh - 178px)' }"> -->
<div class="list" [style]="{ width: '100%', height: 'calc(100vh - 178px)' }">
  <navigation-list [isOpen]="isOpen"></navigation-list>
</div>
<!-- </p-scrollPanel> -->

<navigation-profile class="profile" [class.profile_min]="!isOpen" [isOpen]="isOpen"></navigation-profile>

<p-button
  class="size-button"
  icon="pi"
  [size]="'small'"
  [text]="true"
  [title]="isOpen ? 'Свернуть' : 'Развернуть'"
  (onClick)="onChangeOpen()"
>
  <lucide-icon [img]="isOpen ? ArrowLeftFromLine : ArrowRightFromLine"></lucide-icon>
</p-button>
