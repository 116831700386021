import { RoleCode } from '../types';

export const ROLE_PERMISSIONS: Record<RoleCode, readonly string[]> = {
  ADMIN: [
    'configurationView',

    // ============================================
    'connectionsPageViewing',

    'adaptersPageViewing',
    'adaptersCreate',
    'adaptersEdit',
    'adaptersCopy',
    'adaptersDelete',

    'credentialsPageViewing',
    'credentialsCreate',
    'credentialsEdit',
    'credentialsCopy',
    'credentialsDelete',
    // ============================================

    // ============================================
    'routesView',
    'routesCreate',
    'routesEdit',
    'routesCopy',
    'routesDelete',
    'routesRestart',
    'routesStop',
    'routesTurnOn',
    'routesTurnOff',
    // ============================================

    // ============================================
    'servicesPageViewing',
    'servicesCreate',
    'servicesEdit',
    'servicesCopy',
    'servicesDelete',
    'servicesRestart',
    'servicesStart',
    'servicesStop',
    'servicesTurnOn',
    'servicesTurnOff',
    // ============================================

    // ============================================
    'translatorsPageViewing',
    'translatorsCreate',
    'translatorsEdit',
    'translatorsCopy',
    'translatorsDelete',
    // ============================================

    // ============================================
    'processingConditionsPageViewing',

    'conditionsPageViewing',
    'conditionsCreate',
    'conditionsEdit',
    'conditionsCopy',
    'conditionsDelete',

    'commandsPageViewing',
    'commandsCreate',
    'commandsEdit',
    'commandsCopy',
    'commandsDelete',
    // ============================================

    // ============================================
    'selectionCriteriaView',
    'selectionCriteriaCreate',
    'selectionCriteriaEdit',
    'selectionCriteriaCopy',
    'selectionCriteriaDelete',
    // ============================================

    'replicationDataView',
    'replicationDataEdit',

    'dictionariesView',
    'dictionariesCreate',
    'dictionariesEdit',
    'dictionariesDelete',

    'relationsView',
    'relationsCreate',
    'relationsEdit',
    'relationsDelete',

    'systemLogView',

    'usersPageViewing',
    'usersCreate',
    'usersEdit',
    'usersDelete',

    'modulesSettingsPageViewing',
    'modulesSettingsCreate',
    'modulesSettingsCreate',
    'modulesSettingsEdit',
    'modulesSettingsCopy',
    'modulesSettingsDelete',

    'settingsView',
    'settingsMQView',
    // Временно скрыли
    // 'settingsFileView',
    // 'settingsCertificatesView',

    'settingsNotificationRulesView',
    'settingsNotificationRulesCreate',
    'settingsNotificationRulesEdit',
    'settingsNotificationRulesDelete',

    'projectsPageViewing',

    'userLogPageOldViewing',
    'userLogPageViewing',
    'userLogXMLView',

    'administrationPageViewing',

    'notificationRulesPageViewing',

    'aboutView',
  ],
  USER: ['userLogPageViewing', 'aboutView'],
} as const;
