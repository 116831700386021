{
  "adapters": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип адаптера",
    "type": "Класс",
    "useCredentials": "Подключить учетную запись",
    "credentials": "Учетная запись",
    "period": "Период опроса, мс",
    "attemptsOnError": "Количество повторов при ошибке",
    "timeoutOnError": "Период ожидания при ошибке",
    "uri": "URI",
    "method": "Метод",
    "headers": "Заголовки",
    "body": "Шаблон тела запроса",
    "logLevel": "Уровень логирования"
  },
  "incoming-adapters": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип адаптера",
    "type": "Класс",
    "useCredentials": "Подключить учетную запись",
    "credentials": "Учетная запись",
    "period": "Период опроса, мс",
    "attemptsOnError": "Количество повторов при ошибке",
    "timeoutOnError": "Период ожидания при ошибке",
    "uri": "URI",
    "method": "Метод",
    "headers": "Заголовки",
    "body": "Шаблон тела запроса",
    "logLevel": "Уровень логирования",
    "number": "Порядковый номер"
  },
  "incoming-adapters-group": {
    "sourceHeaderLabel": "Доступные адаптеры",
    "targetHeaderLabel": "Используемые входящие адаптеры"
  },
  "outgoing-adapters": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип адаптера",
    "type": "Класс",
    "useCredentials": "Подключить учетную запись",
    "credentials": "Учетная запись",
    "period": "Период опроса, мс",
    "attemptsOnError": "Количество повторов при ошибке",
    "timeoutOnError": "Период ожидания при ошибке",
    "uri": "URI",
    "method": "Метод",
    "headers": "Заголовки",
    "body": "Шаблон тела запроса",
    "logLevel": "Уровень логирования",
    "number": "Порядковый номер",
    "conditions": "Условие маршрутизации"
  },
  "translators": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип транслятора",
    "type": "Класс",
    "enrichmentRequestAdapter": "Адаптер обогащения",
    "enrichmentRequestConditions": "Правила формирования параметров запроса",
    "enrichmentOutputCondition": "Правило преобразования ответа",
    "command": "Применяемый код",
    "number": "Порядковый номер",
    "sourceHeaderLabel": "Доступные правила",
    "targetHeaderLabel": "Выбранные правила",
    "logLevel": "Уровень логирования"
  },
  "translators-group": {
    "sourceHeaderLabel": "Доступные трансляторы",
    "targetHeaderLabel": "Используемые трансляторы"
  },
  "routers": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "field2": "Адаптер для сообщений с ошибками"
  },
  "routers-group": {
    "errorAdapter": "Адаптер перенаправления сообщения при ошибке",
    "sourceHeaderLabel": "Доступные адаптеры",
    "targetHeaderLabel": "Используемые исходящие адаптеры"
  },
  "routes": {
    "routesId": "ID",
    "routesName": "Название",
    "priority": "Приоритет",
    "srvIdTo": "Адаптер приемника",
    "srvNameTo": "Адаптер приемника",
    "srvIdFrom": "Адаптер источника",
    "srvIdFromPublisher": "Адаптер источника издателя",
    "srvNameFrom": "Адаптер источника",
    "srvIdAnswer": "Адаптер ответа",
    "srvNameAnswer": "Адаптер ответа",
    "typeObmen": "Тип генерации событий",
    "numInst": "Состояние",
    "typeRepl": "Тип маршрута",
    "prAvtostop": "Настройка автоматической приостановки работы маршрута",
    "prRestart": "Признак изменения для перезапуска маршрута",
    "imageUuid": "Контейнер",
    "imageName": "Контейнер",
    "status": "Статус"
  },
  "modules-settings": {
    "uuid": "uuid",
    "title": "Наименование",
    "category": "Категория",
    "kind": "Вид",
    "isCore": "Ядро",
    "type": "Класс",
    "image": "image",
    "description": "Описание",
    "options": "Настройки отображения"
  },
  "projects": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки"
  },
  "services": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "status": "Статус",
    "isEnabled": "Конфигурация",
    "isTemplate": "Настраиваемый сервис",
    "period": "Период опроса, мс",
    "timeoutOnError": "Интервал ожидания после ошибки, мс",
    "stopOnError": "Остановка после ошибки"
  },
  "loggers": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип логера",
    "type": "Класс"
  },
  "commands": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "command": "Применяемый код"
  },
  "conditions": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип",
    "type": "Класс",
    "expression": "Условное выражение",
    "value": "Проверочное значение"
  },
  "credentials": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "description": "Комментарий",
    "options": "Дополнительные настройки",
    "typeTitle": "Тип",
    "type": "Класс",
    "uri": "URI",
    "method": "Метод",
    "headers": "Заголовки",
    "body": "Шаблон тела запроса",
    "username": "Логин",
    "password": "Пароль",
    "header": "Заголовок авторизации",
    "token": "Токен авторизации"
  },
  "user-log": {
    "archivedAt": "Дата архивации",
    "createdAt": "Дата события",
    "dataId": "Номер сообщения в очереди",
    "description": "Описание",
    "headers": "Заголовки сообщения",
    "uuid": "uuid",
    "operationName": "Операция",
    "id": "№",
    "status": "Статус",
    "eventName": "Вид операции",
    "errorCode": "Код ошибки",
    "error": "Ошибка",
    "target": "Получатель",
    "targetId": "ID объекта получателя",
    "source": "Источник",
    "sourceId": "ID объекта источника",
    "moduleName": "Модуль",
    "objectName": "Объект",
    "message": "Сообщение",
    "messageUuid": "ID сообщения",
    "level": "Уровень логирования",
    "dateFrom": "Начиная С",
    "dateTo": "Заканчивая ПО",
    "projectName": "Проект",
    "serviceName": "serviceName"
  },
  "user-log-old": {
    "eventId": "№ события",
    "eventDate": "Дата события",
    "status": "Статус события",
    "nameEvent": "Вид операции",
    "errorCode": "Код ошибки",
    "errorDescription": "Описание",
    "target": "Получатель",
    "targetId": "ID объекта системы-получателя",
    "systemSource": "Источник",
    "sourceId": "ID объекта источника",
    "nameModule": "Сервис",
    "nameObject": "Тип объекта",
    "asId": "ID объекта потребителя",
    "originalMessage": "Передаваемое сообщение",
    "dateFrom": "Начиная С",
    "dateTo": "Заканчивая ПО",
    "replicationId": "ID сообщения",
    "logLevel": "Уровень логирования"
  },
  "users": {
    "id": "ID",
    "uuid": "uuid",
    "username": "Логин",
    "firstName": "Имя",
    "surName": "Отчество",
    "lastName": "Фамилия",
    "position": "Должность",
    "phone": "Телефон",
    "email": "Email",
    "role": "Права доступа",
    "isEnabled": "Статус",
    "createdAt": "createdAt",
    "updatedAt": "updatedAt",
    "loginAt": "loginAt"
  },
  "channels": {
    "title": "Отображаемое название",
    "name": "Идентификатор",
    "incomingAdapter": "Входящий адаптер",
    "outgoingAdapter": "Исходящий адаптер"
  },
  "notification-rules": {
    "uuid": "Идентификатор",
    "address": "Список получателей (через запятую)",
    "systemName": "Имя системы",
    "trackedErrors": "Список ошибок, all все ошибки, кроме игнорируемых (через запятую)",
    "ignoredErrors": "Список игнорируемых ошибок (через запятую)",
    "processedAt": "Последняя дата выборки из логов",
    "processingInterval": "Интервал времени опроса (сек)",
    "messageHeader": "Шапка сообщения",
    "messageText": "Шаблон сообщения",
    "isScheduled": "Статус"
  }
}
