<a
  class="navigation-item"
  [routerLink]="item.link"
  routerLinkActive="navigation-item_active"
  [pTooltip]="!!item.items || isOpen || item.isChild ? '' : item.title"
  [tooltipPosition]="'right'"
>
  <lucide-icon class="navigation-item__icon" [size]="32" [img]="item.icon"></lucide-icon>

  <div *ngIf="item.isChild || isOpen">
    <div class="navigation-item__title">
      {{ item.title }}
    </div>
  </div>
</a>
