import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { NgxPermissionsModule } from 'ngx-permissions';

import { NAVIGATION_ITEMS } from './constants';
import { ListItemComponent } from '../list-item/list-item.component';

@Component({
  selector: 'navigation-list',
  standalone: true,
  imports: [CommonModule, ListItemComponent, TieredMenuModule, NgxPermissionsModule],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  @Input() isOpen = false;

  items = NAVIGATION_ITEMS;
}
