<div class="profile-overlay-panel">
  <div *ngIf="user" class="info-block">
    <div class="info-block__name">{{ user.fullName }}</div>
    <div *ngIf="user.role" class="info-block__role">{{ user.role | dictionaryDisplayValue: roles }}</div>
    <div class="info-block__version">{{ appVersion }}</div>
  </div>

  <div class="button__wrapper">
    <p-button [outlined]="true" class="button" label="Выйти" [size]="'small'" (click)="onClickLogoutButton()" />
  </div>
</div>
