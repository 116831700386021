import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LucideAngularModule } from 'lucide-angular';
import { TooltipModule } from 'primeng/tooltip';

import { NavigationItem } from '../types';

@Component({
  selector: 'list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, LucideAngularModule, TooltipModule],
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() item!: NavigationItem;
  @Input() isOpen = false;
}
