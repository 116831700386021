import {
  FolderKanban,
  FileClock,
  UserPen,
  NotebookPen,
  SlidersHorizontal,
  Settings,
  Share2,
  FolderSync,
  ListRestart,
  BookUser,
  ShieldCheck,
  BellRing,
  KeyRound,
  Cloud,
  Link,
  Code,
} from 'lucide-angular';
import { NavigationItem } from '../types';

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    title: 'Проекты',
    icon: FolderKanban,
    link: '/projects', // TODO: из app.routes.ts
    permissions: ['projectsPageViewing'],
  },
  {
    title: 'Журнал событий',
    icon: FileClock,
    link: '/user-log',
    permissions: ['userLogPageViewing'],
  },
  {
    title: 'Администрирование',
    icon: ShieldCheck,
    link: '/administration', // TODO: из app.routes.ts
    permissions: ['administrationPageViewing'],
    items: [
      {
        title: 'Пользователи',
        icon: UserPen,
        link: '/administration/users', // TODO: из app.routes.ts
        permissions: ['usersPageViewing'],
        isChild: true,
      },
      // {
      //   title: 'Роли и права',
      //   icon: UserPen,
      //   link: '/users', // TODO: из app.routes.ts
      //   permissions: ['usersPageViewing'],
      //   isChild: true,
      // },
      {
        title: 'Уведомления',
        icon: BellRing,
        link: '/administration/notification-rules', // TODO: из app.routes.ts
        permissions: ['notificationRulesPageViewing'],
        isChild: true,
      },
      // {
      //   title: 'Избранное',
      //   icon: UserPen,
      //   link: '/users', // TODO: из app.routes.ts
      //   permissions: ['usersPageViewing'],
      //   isChild: true,
      // },
      {
        title: 'Настройки модулей',
        icon: SlidersHorizontal,
        link: '/administration/modules-settings',
        permissions: ['modulesSettingsPageViewing'],
        isChild: true,
      },
      {
        title: 'Журнал событий (старый)',
        icon: FileClock,
        link: '/administration/user-log-old',
        permissions: ['userLogPageOldViewing'],
        isChild: true,
      },
    ],
  },
  {
    title: 'Планировщик задач',
    icon: NotebookPen,
    link: '/task-scheduler',
    permissions: ['taskSchedulerPageViewing'],
  },
  {
    title: 'Сервисы',
    icon: Settings,
    link: '/services',
    permissions: ['servicesPageViewing'],
  },
  {
    title: 'Подключения',
    icon: Share2,
    link: '/connections',
    permissions: ['connectionsPageViewing'],
    items: [
      {
        title: 'Адаптеры',
        icon: Cloud,
        link: '/connections/adapters', // TODO: из app.routes.ts
        permissions: ['adaptersPageViewing'],
        isChild: true,
      },
      {
        title: 'Учетные записи',
        icon: KeyRound,
        link: '/connections/credentials', // TODO: из app.routes.ts
        permissions: ['credentialsPageViewing'],
        isChild: true,
      },
    ],
  },
  {
    title: 'Обработка сообщения',
    icon: FolderSync,
    link: '/translators',
    permissions: ['translatorsPageViewing'],
  },
  {
    title: 'Условия обработки',
    icon: ListRestart,
    link: '/processing-conditions',
    permissions: ['processingConditionsPageViewing'],
    items: [
      {
        title: 'Условные операторы',
        icon: Link,
        link: '/processing-conditions/conditions', // TODO: из app.routes.ts
        permissions: ['conditionsPageViewing'],
        isChild: true,
      },
      {
        title: 'Исполняемый код',
        icon: Code,
        link: '/processing-conditions/commands', // TODO: из app.routes.ts
        permissions: ['commandsPageViewing'],
        isChild: true,
      },
    ],
  },
  {
    title: 'Логирование',
    icon: BookUser,
    link: '/logging',
    permissions: ['loggingPageViewing'],
  },
];
