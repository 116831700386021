import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProfileOverlayPanelComponent } from '@src/app/modules/profile-overlay-panel';
import { SessionService } from '@src/app/modules/auth';

@Component({
  selector: 'navigation-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  standalone: true,
  imports: [CommonModule, AvatarModule, OverlayPanelModule, ProfileOverlayPanelComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
  @Input() isOpen = false;

  private readonly session = inject(SessionService);

  get user() {
    return this.session.user;
  }
}
