<div class="profile" (click)="overlayPanel.toggle($event)">
  <p-avatar class="profile__avatar" icon="pi pi-user" [size]="'large'" shape="circle"></p-avatar>
  <span *ngIf="isOpen" class="profile__name">{{ user?.fullName }}</span>
</div>

<p-overlayPanel #overlayPanel>
  <ng-template pTemplate="content">
    <app-profile-overlay-panel></app-profile-overlay-panel>
  </ng-template>
</p-overlayPanel>
