import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ROLES } from '@src/app/constants';
import { DictionaryDisplayValuePipe } from '@src/app/pipes';
import { SessionService } from '@src/app/modules/auth';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-profile-overlay-panel',
  templateUrl: './profile-overlay-panel.component.html',
  styleUrl: './profile-overlay-panel.component.scss',
  standalone: true,
  imports: [ButtonModule, CommonModule, DictionaryDisplayValuePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileOverlayPanelComponent {
  readonly roles = ROLES;
  readonly appVersion = environment.appVersion;

  private readonly session = inject(SessionService);

  get user() {
    return this.session.user;
  }

  onClickLogoutButton() {
    this.session.logout();
  }
}
