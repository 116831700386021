{
  "name": "new-integrator",
  "version": "0.17.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --open",
    "start:proxy": "ng serve --proxy-config proxy.conf.json --open",
    "build": "ng build --configuration=development --delete-output-path --output-path ./dist/dev/",
    "build:test": "ng build --configuration=test --delete-output-path --output-path ./dist/test/",
    "build:prod": "ng build --configuration=production --delete-output-path --output-path ./dist/prod/",
    "build:pre-prod": "ng build --configuration=pre-prod --delete-output-path --output-path ./dist/pre-prod/",
    "update-api-auth-models": "openapi-generator-cli generate -i https://dev-integrator.techsrv.ru/auth/api-docs -g typescript-angular -o src/api/auth/output/",
    "update-api-v1-models": "openapi-generator-cli generate -i https://dev-integrator.techsrv.ru/api/v1/api-docs -g typescript-angular -o src/api/v1/output/",
    "update-api-v2-models": "openapi-generator-cli generate -i https://dev-integrator.techsrv.ru/api/v2/api-docs -g typescript-angular -o src/api/v2/output/",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.10",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@auth0/angular-jwt": "^5.2.0",
    "ajv": "^8.17.1",
    "ajv-i18n": "^4.2.0",
    "lucide-angular": "^0.453.0",
    "ngx-permissions": "^17.1.0",
    "primeicons": "^7.0.0",
    "primelocale": "^1.6.0",
    "primeng": "^17.18.11",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "uuid": "^11.1.0",
    "zod": "^3.23.8",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.9",
    "@angular/cli": "^18.2.9",
    "@angular/compiler-cli": "^18.2.8",
    "@openapitools/openapi-generator-cli": "^2.14.1",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ts-to-zod": "^3.15.0",
    "typescript": "~5.4.2"
  }
}
