import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  /**
   * Максимальное количество попыток повтора
   */
  private readonly maxRetries = 100;

  /**
   * Задержка между попытками в миллисекундах
   */
  private readonly retryDelay = 3000;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestId = uuidV4();
    const modifiedRequest = request.clone({
      setHeaders: {
        'x-request-id': requestId,
      },
    });

    return next.handle(modifiedRequest).pipe(
      retry({
        count: this.maxRetries,
        delay: (error: HttpErrorResponse, retryCount: number) => {
          if (error.status === 408) {
            console.debug(`Повторный запрос (попытка ${retryCount}) из-за ошибки 408`);
            return timer(this.retryDelay); // INFO: Задержка перед повтором
          }
          return throwError(() => error); // INFO: Прекратить повторы
        },
      }),
    );
  }
}
