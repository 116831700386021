import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { LucideAngularModule, ArrowRightFromLine, ArrowLeftFromLine } from 'lucide-angular';

import { LogoComponent } from './logo/logo.component';
import { ListComponent } from './list/list.component';
import { ProfileComponent } from './profile/profile.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  standalone: true,
  imports: [ButtonModule, LogoComponent, ListComponent, ProfileComponent, LucideAngularModule, ScrollPanelModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  readonly ArrowRightFromLine = ArrowRightFromLine;
  readonly ArrowLeftFromLine = ArrowLeftFromLine;

  onChangeOpen() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
}
