import { StatusOptions } from '@src/app/components/status/types';

export interface Dictionary {
  id: number | string;
  name: string;
}

// INFO: Адаптеры (DBIDENT)

/**
 * JDBC драйвер
 */
export const JDBC_DRIVERS = [
  'com.microsoft.sqlserver.jdbc.SQLServerDriver',
  'oracle.jdbc.driver.OracleDriver',
  'net.sourceforge.jtds.jdbc.Driver',
  'org.postgresql.Driver',
];

/**
 * Признак текущей БД
 */
export const DB: Dictionary[] = [
  { id: 0, name: 'Другая' },
  { id: 1, name: 'Текущая БД' }, // INFO: (по умолчанию)
];

/**
 * Статус адаптера
 */
export const ADAPTER_STATUS: StatusOptions[] = [
  {
    id: 0,
    text: 'Свободен',
    label: 'Статус',
    severity: 'success',
    message:
      'При сохранении адаптера нужно перезапустить маршруты связанные с этим адаптером, тогда изменения вступят в силу',
  },
  {
    id: 1,
    text: 'В работе',
    label: 'Статус',
    severity: 'warn',
    message:
      'При сохранении адаптера нужно перезапустить маршруты связанные с этим адаптером, тогда изменения вступят в силу',
  },
];

// INFO: Маршруты (ROUTER_REPL)

/**
 * Состояние маршрута
 */
export const ROUTE_STATES: Dictionary[] = [
  { id: 0, name: 'Не используется' },
  { id: 1, name: 'Используется' },
];

/**
 * Статус маршрута
 */
export const ROUTE_STATUS: Dictionary[] = [
  { id: 0, name: 'Остановлен' },
  { id: 1, name: 'Включен' },
];

/**
 * Тип маршрута
 */
export const ROUTE_TYPES: Dictionary[] = [
  { id: 1, name: 'Издатель' },
  { id: 2, name: 'Подписчик' },
  { id: 3, name: 'Точка-точка' },
];

/**
 * Настройка автоматической приостановки работы маршрута
 */
export const ROUTE_AUTO_STOP_SETTINGS: Dictionary[] = [
  { id: 0, name: 'Не останавливать при ошибке' },
  { id: 1, name: 'Останавливать при ошибке' }, // INFO: (по умолчанию)
];

/**
 * Признак изменения для перезапуска маршрута
 */
export const ROUTE_RESTART_FLAGS: Dictionary[] = [
  { id: 0, name: 'Не перезапускать ' },
  { id: 1, name: 'Перезапускать' }, // INFO: (по умолчанию)
];

/**
 * Тип генерации событий
 */
export const EVENT_GENERATION_TYPES: Dictionary[] = [
  { id: 0, name: 'Синхронная' }, // INFO: (по умолчанию)
  { id: 1, name: 'Асинхронная' },
];

/**
 * Приоритет маршрута
 */
export const ROUTE_PRIORITIES: Dictionary[] = [
  { id: 1, name: 'низкий' },
  { id: 5, name: 'средний' }, // INFO: (по умолчанию)
  { id: 10, name: 'высокий' },
];

/**
 * Статус критерия отбора
 */
export const SELECTION_CRITERIA_STATUS: StatusOptions[] = [
  {
    id: 0,
    text: 'Свободен',
    label: 'Статус',
    severity: 'success',
    message:
      'При сохранении критерия отбора нужно перезапустить маршруты связанные с этим критерием, тогда изменения вступят в силу',
  },
  {
    id: 1,
    text: 'В работе',
    label: 'Статус',
    severity: 'warn',
    message:
      'При сохранении критерия отбора нужно перезапустить маршруты связанные с этим критерием, тогда изменения вступят в силу',
  },
];

/**
 * Модуль источника
 */
export const SOURCE_MODULES: Dictionary[] = [
  { id: 0, name: 'SOASOT' }, // INFO: (по умолчанию)
];

/**
 * Роли
 */
export const ROLES: Dictionary[] = [
  { id: 'USER', name: 'Пользователь' }, // INFO: (по умолчанию)
  { id: 'ADMIN', name: 'Администратор' },
];

/**
 * Статусы учетной записи
 */
export const USER_STATUS: Dictionary[] = [
  { id: 0, name: 'Отключена' },
  { id: 1, name: 'Включена' }, // INFO: (по умолчанию)
];

/**
 * Статусы уведомления
 */
export const NOTIFICATION_RULE_STATUS: Dictionary[] = [
  { id: 0, name: 'Выключено' }, // INFO: (по умолчанию)
  { id: 1, name: 'Включено' },
];

/**
 * Признак передачи данных
 */
export const REPLICATION_STATUS: Dictionary[] = [
  { id: 0, name: 'Не отправлены' },
  { id: 1, name: 'Отправлены' },
  { id: 2, name: 'Не определено' },
  { id: 3, name: 'Ошибка отправления' },
];
